var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import ConversationDetails from "./ConversationDetails";
import MessagesBox from "../message/MessagesBox";
import MessageInputField from "../message/MessageInputField";
import ConversationAddParticipants from "./ConversationAddParticipants";
import MessageInputFieldCreateConvo from "../message/MessageInputFieldCreateConvo";
import { useTranslation } from "react-i18next";
var ConversationContainer = function (props) {
    var _a, _b;
    var sid = useSelector(function (state) { return state.sid; });
    var loadingStatus = useSelector(function (state) { return state.loadingStatus; });
    var participants = (_a = useSelector(function (state) { return state.participants; })[sid]) !== null && _a !== void 0 ? _a : [];
    var messages = useSelector(function (state) { return state.messages; });
    var typingData = (_b = useSelector(function (state) { return state.typingData; })[sid]) !== null && _b !== void 0 ? _b : [];
    var lastReadIndex = useSelector(function (state) { return state.lastReadIndex; });
    var _c = __read(useState([]), 2), selectedUsers = _c[0], setSelectedUsers = _c[1];
    var t = useTranslation(["chat"]).t;
    var dispatch = useDispatch();
    var pushMessages = bindActionCreators(actionCreators, dispatch).pushMessages;
    return (_jsx("div", __assign({ className: "container-fluid conversation-main d-flex flex-column", "data-thread-id": sid }, { children: sid && props.conversation && props.client ? (_jsxs(_Fragment, { children: [_jsx(ConversationDetails, { convoSid: sid, convo: props.conversation, participants: participants }), _jsx(MessagesBox, { convoSid: sid, convo: props.conversation, upsertMessage: pushMessages, client: props.client, messages: messages[sid], loadingState: loadingStatus, participants: participants, lastReadIndex: lastReadIndex }, sid), _jsx(MessageInputField, { convoSid: sid, client: props.client, messages: messages[sid], convo: props.conversation, typingData: typingData })] })) : (_jsxs(_Fragment, { children: [props.isCreatingConversation ? (_jsx(ConversationAddParticipants, { isMobile: false, setSelectedUsers: setSelectedUsers })) : null, _jsxs("div", __assign({ className: "convo-welcome-message" }, { children: [t("You are starting a new conversation"), _jsx("p", __assign({ className: "sub-welcome-message" }, { children: t("Type your first message below.") }))] })), props.isCreatingConversation && props.client ? (_jsx(MessageInputFieldCreateConvo, { client: props.client, selectedUsers: selectedUsers })) : null] })) })));
};
export default ConversationContainer;
